import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    Button,
    Grid,
    IconButton,
} from '@mui/material';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface ImageDailogProps {
    isOpen: boolean;
    handleClose: () => void;
    obj: any;
}

const ImageDailog: React.FC<ImageDailogProps> = ({ isOpen, handleClose, obj }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={(event, reason) => handleClose()}
            aria-labelledby="verify-mobile-dialog"
            sx={{
                '& .MuiDialog-paper': {
                    height: '20rem',
                },
            }}
        >
            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column',
                }}
            >
                <img style={{ height: '100%' }} src={obj?.url} alt={obj?.file_name} />
            </DialogContent>
        </Dialog>
    );
};

export default ImageDailog;
