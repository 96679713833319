import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, Box, TextField, Button, Grid } from '@mui/material';

interface VerifyMobileDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    handleVerify: (val: any) => void;
    handleResend: () => void;
    contactNo: string;
}

const VerifyMobileDialog: React.FC<VerifyMobileDialogProps> = ({
    isOpen,
    handleClose,
    handleVerify,
    handleResend,
    contactNo,
}) => {
    const [otp, setOtp] = React.useState<string[]>(Array(5).fill(''));

    const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newOtp = [...otp];
        newOtp[index] = event.target.value;
        setOtp(newOtp);

        // Move to the next input field automatically
        if (event.target.value && index < 4) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }
    };

    const handleVerifyClick = () => {
        if (otp.join('').length == 5) {
            let obj = {
                contact_no: contactNo,
                otp: otp.join(''),
            };
            handleVerify(obj);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="verify-mobile-dialog"
            sx={{
                '& .MuiDialog-paper': {
                    height: '20rem',
                },
            }}
        >
            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column',
                }}
            >
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    Enter OTP Sent on your mobile number
                </div>
                <Box sx={{ textAlign: 'center', marginBottom: '1rem' }}>
                    <Grid container spacing={1} justifyContent="center">
                        {otp.map((value, index) => (
                            <Grid item key={index}>
                                <TextField
                                    id={`otp-input-${index}`}
                                    value={value}
                                    onChange={handleChange(index)}
                                    inputProps={{
                                        maxLength: 1,
                                        style: {
                                            textAlign: 'center',
                                            width: '2rem',
                                            height: '1.25rem',
                                        },
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        marginBottom: '1rem',
                        display: 'flex',
                        flowColumn: 'column',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexFlow: 'column',
                        }}
                    >
                        <Button onClick={handleResend} sx={{ marginBottom: '1rem' }}>
                            Resend OTP
                        </Button>
                        <Button
                            sx={{
                                fontSize: '12px',
                                borderRadius: '1.875rem',
                                padding: '0.2rem 0.75rem',
                                fontWeight: 'bold',
                            }}
                            variant="contained"
                            onClick={handleVerifyClick}
                        >
                            VERIFY MOBILE NUMBER
                        </Button>
                    </div>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default VerifyMobileDialog;
