import { Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import HomeLayout from './layouts/Home/index';
import ThankyouPage from './pages/ThankyouPage';

interface RouteConfig {
    path: string;
    element: React.ReactNode;
    title?: string;
    children?: RouteConfig[];
}
const routes = (isFormSubmitted: boolean): RouteConfig[] => [
    {
        path: '/',
        element: <HomeLayout />,
        title: 'Digital Verification',
        children: [{ path: '/', element: <HomePage />, title: 'main' }],
    },
    {
        path: '/form-submitted',
        element: <ThankyouPage />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
];

export default routes;
